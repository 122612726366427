import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import {
  MatSort,
  MatDialog,
  MatPaginator,
  MatSortable,
  Sort,
} from "@angular/material";
import { Router } from "@angular/router";
import { AdminApprovalComponent } from "../admin-approval/admin-approval.component";
import { RestService } from "../common/rest.service";
import { DataService } from "../common/data.service";
import { interval, Observable, BehaviorSubject } from "rxjs";
import { switchMap, startWith } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-onboard-user",
  templateUrl: "./onboard-user.component.html",
  styleUrls: ["./onboard-user.component.scss"],
})
export class OnboardUserComponent implements OnInit {
  displayedColumns: string[] = [
    "gid",
    "name",
    "emailId",
    "role",
    "project",
    "projectCode",
    "application",
    "date",
    "action",
  ];

  sortedData: any;
  dataSource: MatTableDataSource<any>;
  onboardUserList: any[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  requestCount: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  requestCount$: Observable<number> = this.requestCount.asObservable();

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private restApi: RestService,
    private dataService: DataService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    interval(60000)
      .pipe(
        startWith(0),
        switchMap(() => this.getPendingRequests())
      )
      .subscribe((requestCount) =>
        this.dataService.updateRequestCount(requestCount)
      );
  }

  getPendingRequests(): Observable<any> {
    this.spinner.show();
    this.restApi.getApprovalRequests().subscribe(
      (data: any[]) => {
        this.onboardUserList = data;
        this.dataSource = new MatTableDataSource(this.onboardUserList);
        this.sort.sort({ id: "date", start: "desc" } as MatSortable);
        this.sort.sort({ id: "date", start: "asc" } as MatSortable);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = this.customFilterPredicate();
        this.requestCount.next(data.length); // Set pending request count for notification
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.errorMsg, "Error Occured");
      }
    );
    return this.requestCount;
  }

  syncAllApp() {
    this.toastr.info("Application sync started in background..");
    this.spinner.show();
    this.restApi.syncAllApp().subscribe(
      (data) => {
        this.toastr.info(data.response, data.message);
        console.log(JSON.stringify(data));
        this.spinner.hide();
      },
      (error) => {
        if (error != null && error.error != null) {
          this.toastr.error(error.error.error, "HTTP Status : " + error.status);
        } else if (!error != null) {
          this.toastr.error(error.error, "HTTP Status : " + error.status);
        }
        console.log(JSON.stringify(error));
        this.spinner.hide();
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  customFilterPredicate() {
    const myFilterPredicate = (data: any, filter): boolean => {
      const dataStr = JSON.stringify(data).toLowerCase();
      return dataStr.indexOf(filter) !== -1;
    };
    return myFilterPredicate;
  }

  sortData(sort: Sort) {
    const data = this.onboardUserList.slice();
    if (!sort.active || sort.direction === "") {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "name":
          return this.compare(a.lastName, b.lastName, isAsc);
        case "role":
          return this.compare(a.project.role, b.project.role, isAsc);
        case "project":
          return this.compare(
            a.project.detail.name,
            b.project.detail.name,
            isAsc
          );
        case "date":
          return this.compare(
            a.project.requestDate,
            b.project.requestDate,
            isAsc
          );
        default:
          return 0;
      }
    });

    this.dataSource = new MatTableDataSource(this.sortedData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openDialog(userData): void {
    const dialogRef = this.dialog.open(AdminApprovalComponent, {
      width: "62.5rem",
      data: {
        userData,
        buttonLabel: "Approve",
        isEditMode: true,
        isFormChanged: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getPendingRequests();
      }
    });
  }
}
