import {
  Component,
  OnInit,
  Inject,
  ChangeDetectorRef,
  NgZone,
  ChangeDetectionStrategy,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
  MatTableDataSource,
  MatAutocompleteTrigger,
} from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { RestService } from "../common/rest.service";
import { RequestConfirmationDialogComponent } from "../request-confirmation-dialog/request-confirmation-dialog.component";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { MsalAuthService } from "../msal-auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-admin-approval",
  templateUrl: "./admin-approval.component.html",
  styleUrls: ["./admin-approval.component.scss"],
})
export class AdminApprovalComponent implements OnInit {
  approvalForm: FormGroup;
  applicationArray: any = new Array();
  projects: any;
  roles: any;
  code: any;
  statusValues: any;
  showStatus = false;
  requestedApplicationArray = [];
  isApplicationCheckedArray = [];
  formArrayApplicationValues = [];
  unselectedApplicationArray = new Array();
  actionSelectedApplicationArray = new Array();
  clicked = false;
  readonly = "true";
  superAdminOption = false;
  dialogResult = false;
  checkRemarksEmpty = false;
  editMode: boolean = false;
  isEditMode: boolean = false;

  editProjectMode = false;
  hasConfirmedEditRole = false;
  hasConfirmedEditProject = false;
  hasConfirmedGeneralEdit = false;

  rolesOptions: string[] = ["Super Admin", "Site Admin", "Site User"];

  projectInputControl = new FormControl();
  projectArray: any[] = [];

  otherUsers: boolean;
  projectVerified: boolean;
  apps: any[];
  userRole: string;
  projectIdOrNameObj: string;

  unselectedApps: any[] = [];
  filteredOptions: Observable<string[]>;

  currentUser: any;
  userList: any[] = [];
  dataSource: MatTableDataSource<any>;

  userLoggedIn = false;
  userName: string;
  isLoading = false;
  loading: boolean = true;
  submitted = false;
  projectNameAndCode =
    this.data.userData.project.detail.code +
    " - " +
    this.data.userData.project.detail.name;
  isSelected = true;
  isButtonDisabled: boolean = false;
  oldProjectName: any | null = null;
  isProjectSelected: boolean = false;
  oldRoleValue: string | null = null;
  projectInput: string | null = null;
  applicationsFetched: boolean = false;
  shouldHideApplications = false;
  shouldShowFetchButton = false;
  checkedApps: string[] = [];

  selectedProject: any;
  isTouched = false;
  editButtonEnabled = true;
  statusChanged: boolean = false;
  buttonLabel: string = "Update";
  isRejectClicked: boolean = false;
  isFormChanged: boolean = false;
  isSuperAdminUser: boolean = false;
  isDestroyed: boolean = false;
  private projectArraySubject = new BehaviorSubject<any[]>([]);
  projectArray$ = this.projectArraySubject.asObservable();
  @ViewChild(MatAutocompleteTrigger, { static: false })
  autoCompleteTrigger!: MatAutocompleteTrigger;
  isProjectEditable: boolean = false;

  constructor(
    private restService: RestService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdminApprovalComponent>,
    public confirmDialog: MatDialog,
    public dialog: MatDialog,
    private restApi: RestService,

    private msalAuth: MsalAuthService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private cookie: CookieService
  ) {
    if (data.buttonLabel) {
      this.buttonLabel = data.buttonLabel;
    }

    if (typeof data.isEditMode === "boolean") {
      this.isEditMode = data.isEditMode;
    }
    if (typeof data.isFormChanged === "boolean") {
      this.isFormChanged = data.isFormChanged;
    }
    dialogRef.disableClose = true;
    this.projectInputControl = new FormControl("");
    this.approvalForm = this.fb.group({
      projectName: this.projectInputControl,
    });
  }

  ngOnInit() {
    this.getAllProjectDetails();

    this.isSuperAdmin().subscribe((isSuperAdmin) => {
      this.isSuperAdminUser = isSuperAdmin;
    });

    this.userLoggedIn = this.msalAuth.loggedIn;
    this.userName = this.msalAuth.loggedInUser;
    if (sessionStorage.getItem("userName")) {
      this.userLoggedIn = true;
      this.userName = sessionStorage.getItem("userName");
    }

    this.initializeForm();

    this.approvalForm.get("status").valueChanges.subscribe((newStatus) => {
      if (newStatus) {
        this.statusChanged = true;

        this.cdr.detectChanges();
      }
    });
    this.approvalForm.get("role").valueChanges.subscribe((newRole) => {
      this.onRoleChange(newRole);
      this.cdr.detectChanges();
    });
    this.approvalForm.valueChanges.subscribe(() => {
      this.isFormChanged = this.approvalForm.dirty || this.approvalForm.touched;
    });
    this.isApplicationCheckedArray.forEach((app) => {
      const control = this.fb.control(false);
      (this.approvalForm.get("applicationName") as FormArray).push(control);
    });
    this.filteredOptions = this.approvalForm
      .get("projectName")
      .valueChanges.pipe(
        startWith(""),
        map((value) => this._filter(value))
      );
  }

  initializeForm() {
    if (this.data.userData) {
      this.projectInputControl = new FormControl();
      this.otherUsers = false;

      this.getApplications(this.data.userData.project.detail.name);
      this.getRequestedApplication(
        this.data.userData.project.detail.applicationName
      );
      this.getApplicationCode(this.data.userData.project.detail.code);
      this.getRoles();
      this.getStatus();
      this.checkForStatus(this.data.userData);
      this.checkForRemarks(this.data.userData.project.detail.remarks);

      this.approvalForm = this.fb.group({
        name: this.data.userData.lastName + ", " + this.data.userData.firstName,
        emailId: this.data.userData.emailId,
        gid: this.data.userData.gid,
        role: [this.data.userData.project.role, Validators.required],
        projectName: [this.projectNameAndCode, Validators.required],
        projectCode: [
          this.data.userData.project.detail.code,
          Validators.required,
        ],
        status: "ACTIVE",
        department: "Civil Construction",
        approved: "true",
        applicationUnselected: [],
        actionSelected: [],
        remarks: [null],
        applicationName: this.fb.array([]),
      });

      this.approvalForm.setControl(
        "applicationName",
        this.fb.array(this.requestedApplicationArray)
      );

      this.approvalForm.get("role").valueChanges.subscribe((value) => {
        this.checkForSuperAdmin(value);
      });

      this.checkForSuperAdmin(this.approvalForm.value.role);
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  private _filter(value: string): string[] {
    if (!this.projectArray || this.projectArray.length === 0) {
      console.warn("Project array is empty, skipping filter.");
      return [];
    }

    const filterValue = value.toLowerCase();
    return this.projectArray.filter(
      (option) =>
        option.name.toLowerCase().includes(filterValue) ||
        option.code.toLowerCase().includes(filterValue)
    );
  }

  openProjectDropdown() {
    if (this.autoCompleteTrigger) {
      this.filteredOptions = of(this.projectArray);
      this.autoCompleteTrigger.openPanel();
    }
  }

  filterProjects() {
    const inputValue =
      this.approvalForm.controls["projectName"].value.toLowerCase();

    if (!inputValue.trim()) {
      this.filteredOptions = of(this.projectArray);
      return;
    }

    this.filteredOptions = of(
      this.projectArray.filter(
        (project) =>
          project.code.toLowerCase().includes(inputValue) ||
          project.name.toLowerCase().includes(inputValue)
      )
    );
  }

  getUncheckedApps(appName: string, isChecked: boolean) {
    const index = this.apps.findIndex((app) => app.name === appName);
    if (index >= 0) {
      this.apps[index].checked = isChecked;
    }

    if (!isChecked && !this.unselectedApps.includes(appName)) {
      this.unselectedApps.push(appName);
    } else if (isChecked && this.unselectedApps.includes(appName)) {
      const unselectedIndex = this.unselectedApps.indexOf(appName);
      this.unselectedApps.splice(unselectedIndex, 1);
    }

    if (isChecked && !this.checkedApps.includes(appName)) {
      this.checkedApps.push(appName);
    } else if (!isChecked && this.checkedApps.includes(appName)) {
      const checkedIndex = this.checkedApps.indexOf(appName);
      this.checkedApps.splice(checkedIndex, 1);
    }

    this.approvalForm.setControl(
      "applicationName",
      this.fb.array(this.checkedApps.map((name) => this.fb.control(name)))
    );
  }

  appName(appName: any): import("@angular/forms").AbstractControl {
    throw new Error("Method not implemented.");
  }

  fetchApplications() {
    this.applicationsFetched = true;
    this.shouldHideApplications = false;

    const projectInput = this.approvalForm.get("projectName").value;

    this.restService.getApplications().subscribe(
      (response) => {
        this.apps = response.map((app) => ({ ...app, checked: true }));

        this.approvalForm.patchValue({
          projectName: projectInput,
        });

        this.cdr.detectChanges();
      },
      (error) => {
        console.error("Error fetching applications", error);
      }
    );
  }

  checkForRemarks(data: any) {
    if (!data) {
      this.checkRemarksEmpty = true;
    }
  }
  checkForStatus(data: any) {
    if (data.status) {
      this.showStatus = true;
    }
  }

  isSuperAdmin(): Observable<boolean> {
    return this.restService.getCurrentUserRole().pipe(
      map((data: string[]) => {
        return data.length === 1 && data[0] === "Super Admin";
      })
    );
  }

  onRoleChange(selectedRole: string): void {
    this.editProjectMode = true;

    if (
      this.data.userData.project.role === "Super Admin" &&
      (selectedRole === "Site Admin" || selectedRole === "Site User")
    ) {
      this.isProjectEditable = true;
    } else {
      this.isProjectEditable = false;
    }

    if (selectedRole === "Super Admin") {
      this.shouldShowFetchButton = false;
      this.shouldHideApplications = true;
    } else {
      this.shouldHideApplications = false;
    }

    if (this.approvalForm.dirty) {
      const currentProject = this.approvalForm.get("projectName").value;
      const currentApplications =
        this.approvalForm.get("applicationName").value;

      if (selectedRole !== "Super Admin") {
        this.approvalForm.patchValue({
          projectName: currentProject,
          applicationName: currentApplications,
        });
      }
    }

    this.projectVerified = !!selectedRole;
  }

  toggleEditMode(event: Event): void {
    this.editButtonEnabled = false;
    this.isEditMode = true;
    this.editProjectMode = true;

    // Ensure `data` and nested fields are valid
    if (this.data.userData.project.detail) {
      const oldProjectValue = {
        projectCode: this.data.userData.project.detail.code,
        projectName: this.data.userData.project.detail.name,
      };

      this.oldProjectName = oldProjectValue; // Store the project object
    } else {
      console.warn("No valid project details in data.");
    }

    if (!this.hasConfirmedEditRole) {
      this.openConfirmationDialog("Do you wish to edit the details?", () => {
        this.editMode = !this.editMode;
        if (this.editMode) {
          this.shouldHideApplications = false;
          this.oldRoleValue = this.approvalForm.get("role").value;
        }
        this.hasConfirmedEditRole = true;
      });
    }
  }

  isAllApplicationsSelected(): boolean {
    return this.approvalForm.get("applicationName").value.includes("ALL");
  }

  openConfirmationDialog(message: string, onConfirm: () => void) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "38%",
      data: { message: message },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        onConfirm();
      }
    });
  }
  onProjectCleared(): void {
    const projectControl = this.approvalForm.get("projectName");
    if (!projectControl.value) {
      this.projectVerified = false; // Hide applications if the project is cleared
      this.approvalForm.controls["selectedApps"].reset(); // Optionally reset selected apps
    }
  }

  onProjectSelected(project: any): void {
    // Check for invalid project selection
    if (
      !project ||
      typeof project.code === "undefined" ||
      typeof project.name === "undefined"
    ) {
      this.toastr.warning(
        "Invalid project selection. Please select a valid project."
      );
      return;
    }

    // Store selected project
    this.selectedProject = {
      projectCode: project.code,
      projectName: project.name,
    };

    // Update form control value
    this.approvalForm
      .get("projectName")
      .setValue(`${project.code} - ${project.name}`, { emitEvent: false });
    this.filteredOptions = of(this.projectArray);

    this.verify({ code: project.code, name: project.name });

    // Clear application form array
    const applicationNameArray = this.approvalForm.get(
      "applicationName"
    ) as FormArray;
    applicationNameArray.clear();

    // Hide applications and reset application-related flags
    this.shouldHideApplications = true;
    this.applicationsFetched = false;

    // Verify if the selected project is valid
    this.projectVerified = !!this.selectedProject;
    this.projectInput = this.selectedProject;

    // Handle specific case for "ALL - PALL"
    if (project.name === "ALL - PALL") {
      this.selectedProject = null;
      this.approvalForm.get("projectName").setValue(null);
      this.shouldShowFetchButton = true;
    } else {
      this.shouldShowFetchButton = true;
    }

    setTimeout(() => {
      this.verify({ code: project.code, name: project.name });
    }, 0);
  }

  verify(project: { code: string; name: string }): void {
    this.shouldHideApplications = true;
    this.isLoading = true;
    this.shouldShowFetchButton = false;
    this.applicationsFetched = false;

    // Display the project as a combination of code and name in the input field
    const projectDisplayValue = `${project.code} - ${project.name}`;
    this.approvalForm
      .get("projectName")
      .setValue(projectDisplayValue, { emitEvent: false });

    this.restService
      .getProject(project.code)
      .pipe(
        switchMap((projectResult) => {
          if (!projectResult || !projectResult.apps) {
            throw new Error("Project applications not found");
          }

          const hasLOP = projectResult.apps.some(
            (app) => app.name.toUpperCase() === "LOP"
          );

          if (hasLOP) {
            return this.restService.getUserRoleInIcmmsLop(project.code).pipe(
              map((icmmsResult) => ({
                projectApps: projectResult.apps,
                icmmsRole: icmmsResult.response,
              }))
            );
          } else {
            return of({ projectApps: projectResult.apps, icmmsRole: null });
          }
        })
      )
      .subscribe(
        ({ projectApps, icmmsRole }) => {
          if (icmmsRole === "CONTRACTOR") {
            this.apps = projectApps.filter(
              (app) => app.name.toUpperCase() === "LOP"
            );
            this.toastr.info("LOP contractor can access only LOP App", "", {
              timeOut: 5000,
            });
          } else if (icmmsRole === "NOT FOUND" || icmmsRole === "ERROR") {
            this.apps = projectApps.map((app) => ({
              ...app,
              disabled: app.name.toUpperCase() === "LOP",
            }));
            if (icmmsRole === "ERROR") {
              this.toastr.warning(
                "Access to LOP app is restricted due to an error in the ICMMS system",
                "",
                { timeOut: 5000 }
              );
            }
          } else {
            this.apps = projectApps;
          }

          this.projectVerified = true;
          this.applicationsFetched = true;
          this.shouldHideApplications = false;
        },
        (error) => {
          this.toastr.error(
            error.message || "Failed to fetch applications",
            "",
            { timeOut: 5000 }
          );
          this.projectVerified = false;
          this.applicationsFetched = false;
          this.shouldHideApplications = true;
        },
        () => {
          this.isLoading = false;
        }
      );

    console.log("Verify called with:", project);
  }

  checkForSuperAdmin(roleDesired: any) {
    if (roleDesired === "Super Admin") {
      this.superAdminOption = true;
      this.disableProjectControls(true);
    } else if (roleDesired === "Site Admin" || roleDesired === "Site User") {
      this.superAdminOption = false;
      this.disableProjectControls(false, true);
    } else {
      this.superAdminOption = false;
      this.disableProjectControls(false, false);
    }
  }

  disableProjectControls(
    disable: boolean,
    disableProjectCode: boolean = false
  ) {
    if (disable) {
      this.approvalForm.get("projectName").disable();
      this.approvalForm.get("projectCode").disable();
      (this.approvalForm.get("applicationName") as FormArray).disable();
    } else {
      this.approvalForm.get("projectName").enable();
      if (disableProjectCode) {
        this.approvalForm.get("projectCode").disable();
      } else {
        this.approvalForm.get("projectCode").enable();
      }
      (this.approvalForm.get("applicationName") as FormArray).enable();
    }
  }

  getRequestedApplication(data) {
    this.requestedApplicationArray = data;
  }

  checkApplicationValues(applicationArray, formArray) {
    for (const form of formArray) {
      this.formArrayApplicationValues.push(form);
    }

    for (const app of applicationArray) {
      if (this.formArrayApplicationValues.includes(app)) {
        this.isApplicationCheckedArray.push({
          applicationName: app,
          checkboxValue: true,
        });
      } else {
        this.isApplicationCheckedArray.push({
          applicationName: app,
          checkboxValue: false,
        });
      }
    }
  }

  getStatus() {
    this.statusValues = ["ACTIVE", "INACTIVE"];
  }
  getApplications(projectName: any) {
    this.restService.getProjectUserApps(projectName).subscribe((result) => {
      this.applicationArray = result;

      this.checkApplicationValues(
        this.applicationArray,
        this.requestedApplicationArray
      );
    });
  }
  getAllProjectDetails() {
    this.restService.getAllProjectDetails().subscribe(
      (res) => {
        if (res && res.length > 0) {
          this.projectArray = res.filter(
            (p) => p.name !== "ALL" && p.status === "ACTIVE"
          );
        } else {
          console.warn("No projects found in API response.");
        }
      },
      (error) => {
        console.error("Error fetching project details:", error);
      }
    );
  }

  getApplicationCode(projectCode: any) {
    this.restService.getUserRoleInIcmmsLop(projectCode).subscribe((result) => {
      this.code = result;
    });
  }

  getRoles() {
    this.restService.getRoles().subscribe((d) => {
      this.roles = d;
    });
  }

  checkClicked() {
    this.clicked = true;
  }

  openDialog(title: string, message: string, project?: any): void {
    const dialogConfirmRef = this.confirmDialog.open(
      RequestConfirmationDialogComponent,
      {
        width: "50%",
        data: {
          title,
          message,
        },
      }
    );

    dialogConfirmRef.afterClosed().subscribe((result) => {
      this.dialogResult = result;
      if (this.dialogResult) {
        this.request(project); // Pass the project parameter if provided

        this.dialogResult = false;
      }
    });
  }

  validateForm(): boolean {
    this.submitted = true;

    if (this.approvalForm.dirty && this.approvalForm.get("role").dirty) {
      this.applicationsFetched = true;
    }

    const applicationArray = this.approvalForm.get(
      "applicationName"
    ) as FormArray;

    if (!applicationArray) {
      console.error("applicationName FormArray is not defined.");
      return false;
    }

    if (
      !this.applicationsFetched &&
      this.approvalForm.value.role !== "Super Admin"
    ) {
      const projectTouched = this.approvalForm.get("projectName").touched;
      const roleTouched = this.approvalForm.get("role").touched;

      // If project is touched and applications are not fetched
      if (projectTouched && !this.approvalForm.get("role").dirty) {
        this.shouldShowFetchButton = true; // Set the flag to true
        this.cdr.detectChanges(); // Trigger change detection to update the UI

        this.toastr.warning(
          "Please fetch applications before submitting validate."
        );
        return false; // Prevent form submission
      }

      // If both project and role are touched but applications are not fetched
      if (projectTouched && roleTouched) {
        this.shouldShowFetchButton = true; // Set the flag to true
        this.cdr.detectChanges(); // Trigger change detection to update the UI

        this.toastr.warning(
          "Please fetch applications before submitting validate."
        );
        return false; // Prevent form submission
      }
    }

    // If no applications are selected, show warning

    if (applicationArray.length === 0) {
      this.toastr.warning("At least one application must be selected valid.");
      return false;
    }

    return true; // Return true if all validations pass
  }

  onAccept(project: any) {
    const applicationArray = this.approvalForm.get(
      "applicationName"
    ) as FormArray;

    // Check if any of the application controls are touched
    const anyApplicationTouched = applicationArray.controls.some(
      (control) => control.touched
    );

    const currentRole = this.data.userData.project.role;
    const selectedRole = this.approvalForm.value.role;

    // First condition: if not Super Admin and project/applications are not touched, allow execution but don't show toastr
    if (
      selectedRole !== "Super Admin" &&
      !anyApplicationTouched &&
      !this.approvalForm.get("projectName").touched
    ) {
      // No toastr or return; just proceed
    }

    // Second condition: Show warning only if project is not touched, role is not Super Admin, and role is not switching between Site Admin and Site User
    const isRoleChangeBetweenSiteRoles =
      (currentRole === "Site Admin" && selectedRole === "Site User") ||
      (currentRole === "Site User" && selectedRole === "Site Admin");

    if (
      !this.approvalForm.get("projectName").touched && // Project is not touched
      selectedRole !== "Super Admin" && // Role is not Super Admin
      this.approvalForm.get("role").touched && // Role is touched
      !isRoleChangeBetweenSiteRoles // Only show warning if not switching between Site Admin and Site User
    ) {
      this.toastr.warning("Project is required for the role");
      this.approvalForm.get("projectName").setValue(null);
      return; // Exit the function
    }

    if (
      !this.applicationsFetched &&
      this.approvalForm.value.role !== "Super Admin"
    ) {
      const projectTouched = this.approvalForm.get("projectName").touched;
      const roleTouched = this.approvalForm.get("role").touched;

      // If project is touched and applications are not fetched
      if (projectTouched && !this.approvalForm.get("role").dirty) {
        this.shouldShowFetchButton = true; // Set the flag to true
        this.cdr.detectChanges(); // Trigger change detection to update the UI

        this.toastr.warning(
          "Please fetch applications before submitting validate."
        );
        return false; // Prevent form submission
      }

      // If both project and role are touched but applications are not fetched
      if (projectTouched && roleTouched) {
        this.shouldShowFetchButton = true; // Set the flag to true
        this.cdr.detectChanges(); // Trigger change detection to update the UI

        this.toastr.warning(
          "Please fetch applications before submitting validate."
        );
        return false; // Prevent form submission
      }
    }

    // Existing logic for editMode and further steps
    if (this.editMode) {
      this.applicationsFetched =
        this.approvalForm.value.role === "Super Admin"
          ? true
          : this.applicationsFetched;

      if (this.statusChanged) {
        this.handleStatusChange(project);
        return;
      }

      if (!this.validateForm()) {
        return;
      }

      this.approvalForm.get("remarks").clearValidators();
      this.approvalForm.get("remarks").updateValueAndValidity();

      let message = "Do you want to make the user inactive?";

      // Check if the role is being changed from Super Admin to any other role
      if (
        this.data.userData.project.role === "Super Admin" &&
        selectedRole !== "Super Admin"
      ) {
        message =
          "User is currently Super Admin. Do you wish to revoke this access and update?";
        this.openDialog("Confirmation", message);
        return;
      }
      if (
        this.data.userData.project.role === "Site User" &&
        selectedRole === "Site Admin"
      ) {
        message =
          "User is currently Site User. Do you wish to revoke this role access and update to Site Admin?";
        this.openDialog("Confirmation", message);
        return;
      }
      if (
        this.data.userData.project.role === "Site Admin" &&
        selectedRole === "Site User"
      ) {
        message =
          "User is currently Site Admin. Do you wish to revoke this role access and update to Site User?";
        this.openDialog("Confirmation", message);
        return;
      }

      if (this.approvalForm.value.role === "Super Admin") {
        this.approvalForm.patchValue({
          projectName: "ALL",
          applicationName: ["ALL"],
        });
        const projectForSuperAdmin = {
          name: "ALL",
          code: "ALL",
        };
        message =
          "Super Admin permissions will apply to all projects and applications. Do you wish to continue?";
        this.openDialog("Confirmation", message);

        //  the project object is passed for super admin
        this.request(projectForSuperAdmin);
      } else if (this.approvalForm.value.status === "ACTIVE") {
        if (this.applicationArray.length === 0) {
          message = "Application is not selected. Do you wish to continue?";
          this.openDialog("Confirmation", message);
        } else {
          this.request(project); //  non-Super Admin roles, use the selected project
        }
      } else {
        let projectInput = this.approvalForm.get("projectName").value;

        this.selectedProject = project;
        this.approvalForm
          .get("projectName")
          .setValue(project.code, { emitEvent: false });
        // Store project as key-value pairs
        this.selectedProject = {
          projectCode: project.code,
          projectName: project.name,
        };
        if (!project.name && this.oldProjectName.projectName) {
          project.name = this.oldProjectName.projectName;
          project.code = this.oldProjectName.projectCode;
        }
        const updatedRole = this.approvalForm.get("role").value;
        this.approvalForm.get("role").setValue(updatedRole);

        let applicationNameValue =
          this.approvalForm.get("applicationName").value;
        if (!Array.isArray(applicationNameValue)) {
          applicationNameValue = applicationNameValue
            ? [applicationNameValue]
            : [];
        }

        this.approvalForm.patchValue({
          projectName: project.name,
          applicationName: applicationNameValue,
        });

        message =
          "Inactivating will revoke " +
          this.approvalForm.value.name +
          "'s access. Do you wish to continue?";
        this.openDialog("Confirmation", message);
      }
    } else {
      this.applicationsFetched = true;
      this.shouldHideApplications = false;
      this.isEditMode = true;

      this.approvalForm.get("remarks").clearValidators();
      this.approvalForm.get("remarks").updateValueAndValidity();
      let message = "Do you want to make the user inactive?";

      if (this.approvalForm.value.status === "ACTIVE") {
        if (this.applicationArray.length === 0) {
          message = "Application is not selected. Do you wish to continue?";
          this.openDialog("Confirmation", message);
        } else {
          this.requestedit(project);
        }
      } else {
        message =
          "Inactivating will revoke " +
          this.approvalForm.value.name +
          "'s access. Do you wish to continue?";
        this.openDialog("Confirmation", message);
      }
      this.approvalForm.markAsPristine();
      this.isFormChanged = false;
    }
  }

  requestedit(project: any) {
    this.isEditMode = true;

    if (this.clicked) {
      const applicationFormArray = this.applicationArray.map((app) =>
        this.fb.control(app)
      );
      this.approvalForm.setControl(
        "applicationName",
        this.fb.array(applicationFormArray)
      );

      const unselectedFormArray = this.unselectedApplicationArray.map((app) =>
        this.fb.control(app)
      );
      this.approvalForm.setControl(
        "applicationUnselected",
        this.fb.array(unselectedFormArray)
      );

      const actionSelectedFormArray = this.actionSelectedApplicationArray.map(
        (app) => this.fb.control(app)
      );
      this.approvalForm.setControl(
        "actionSelected",
        this.fb.array(actionSelectedFormArray)
      );
    }

    const projectInput = this.approvalForm.get("projectName").value;

    this.selectedProject = project;
    this.approvalForm.get("projectName").value;

    // Store project as key-value pairs
    this.selectedProject = {
      projectCode: this.data.userData.project.detail.code,
      projectName: this.data.userData.project.detail.name,
    };

    this.approvalForm.patchValue({
      projectName: this.data.userData.project.detail.name,
    });

    if (this.approvalForm.invalid) {
      return;
    }

    this.closeForm();
    setTimeout(() => {
      this.spinner.show();
    }, 5000);
    this.restService.updatePermission(this.approvalForm.value).subscribe(
      (res) => {
        this.onClose(true);
        this.syncApplications();
      },
      (error) => {
        this.toastr.error(error.error.message);
        this.spinner.hide();
      }
    );
  }

  handleStatusChange(project: any) {
    this.applicationsFetched = true;

    // Initialize applicationName value as an array
    let applicationNameValue = this.approvalForm.get("applicationName").value;
    if (!Array.isArray(applicationNameValue)) {
      applicationNameValue = applicationNameValue ? [applicationNameValue] : [];
    }
    const selectedProject = project || this.selectedProject || {};

    // Store selectedProject as key-value pairs
    this.selectedProject = {
      projectCode: selectedProject.projectCode || "",
      projectName: selectedProject.projectName || "",
    };

    // Set the projectName and other form values
    this.approvalForm.patchValue({
      projectName: this.selectedProject.projectName,
      applicationName: applicationNameValue,
    });

    // Display a confirmation message for status change
    const message =
      "Inactivating will revoke " +
      this.approvalForm.value.name +
      "'s access. Do you wish to continue?";

    // Open confirmation dialog
    this.openDialog("Confirmation", message);
  }

  onChangeEventFunc(applicationName: string, isChecked: boolean) {
    if (!applicationName) {
      console.warn("Application name is null or undefined.");
      return;
    }

    const appIndex = this.isApplicationCheckedArray.findIndex(
      (app) => app.applicationName === applicationName
    );

    if (appIndex >= 0) {
      this.isApplicationCheckedArray[appIndex].checkboxValue = isChecked;
      this.checkClicked();
      const applicationArray = this.approvalForm.get(
        "applicationName"
      ) as FormArray;

      if (applicationArray && applicationArray.controls[appIndex]) {
        applicationArray.controls[appIndex].markAsTouched();
      }

      this.applicationArray = this.applicationArray || [];
      this.actionSelectedApplicationArray =
        this.actionSelectedApplicationArray || [];
      this.unselectedApplicationArray = this.unselectedApplicationArray || [];

      if (isChecked) {
        // If checked, add to actionSelectedApplicationArray
        if (!this.actionSelectedApplicationArray.includes(applicationName)) {
          this.actionSelectedApplicationArray.push(applicationName);
        }

        // Remove from unselected applications if it was previously unselected
        const unselectedIndex =
          this.unselectedApplicationArray.indexOf(applicationName);
        if (unselectedIndex >= 0) {
          this.unselectedApplicationArray.splice(unselectedIndex, 1);
        }
      } else {
        // If unchecked, remove from actionSelectedApplicationArray
        const actionIndex =
          this.actionSelectedApplicationArray.indexOf(applicationName);
        if (actionIndex >= 0) {
          this.actionSelectedApplicationArray.splice(actionIndex, 1);
        }

        // Add to unselected applications if it's not already there
        if (!this.unselectedApplicationArray.includes(applicationName)) {
          this.unselectedApplicationArray.push(applicationName);
        }
      }

      this.isFormChanged = true;
    } else {
      console.warn(`Application with name ${applicationName} not found.`);
    }
  }

  request(project?: any) {
    const selectedProject = project || this.selectedProject || {};

    if (
      (!selectedProject.projectName || !selectedProject.projectCode) &&
      this.approvalForm.value.role !== "Super Admin"
    ) {
      selectedProject.projectName = this.oldProjectName.projectName || "";
      selectedProject.projectCode = this.oldProjectName.projectCode || "";
    }

    if (
      (!selectedProject.projectName || !selectedProject.projectCode) &&
      this.approvalForm.value.role !== "Super Admin"
    ) {
      this.toastr.error("Please select a valid project.");
      return;
    }

    // Automatically set applicationsFetched for Super Admin
    this.applicationsFetched =
      this.approvalForm.value.role === "Super Admin" ||
      this.applicationsFetched;

    if (!this.validateForm()) return;

    // Determine project name and set it in the form
    const projectNameValue =
      this.approvalForm.value.role === "Super Admin"
        ? "ALL"
        : selectedProject.projectName;

    // Set project details in the form
    this.approvalForm
      .get("projectName")
      .setValue(projectNameValue, { emitEvent: false });

    this.selectedProject =
      this.approvalForm.value.role === "Super Admin"
        ? { projectCode: "ALL", projectName: "ALL" }
        : {
            projectCode: selectedProject.projectCode,
            projectName: selectedProject.projectName,
          };

    // Handle application arrays for the form
    const applicationArray = this.applicationArray.map((app) =>
      this.fb.control(app)
    );
    this.approvalForm.setControl(
      "applicationName",
      this.fb.array(applicationArray)
    );

    const unselectedFormArray = this.unselectedApplicationArray.map((app) =>
      this.fb.control(app)
    );
    this.approvalForm.setControl(
      "applicationUnselected",
      this.fb.array(unselectedFormArray)
    );

    const actionSelectedFormArray = this.actionSelectedApplicationArray.map(
      (app) => this.fb.control(app)
    );
    this.approvalForm.setControl(
      "actionSelected",
      this.fb.array(actionSelectedFormArray)
    );

    // Handle conditions based on role and project
    if (this.approvalForm.value.role === "Super Admin") {
      this.approvalForm.setControl(
        "applicationName",
        this.fb.array(["ALL"].map((app) => this.fb.control(app)))
      );
      this.approvalForm
        .get("projectName")
        .setValue("ALL", { emitEvent: false });
    } else if (
      this.approvalForm.get("role").touched &&
      this.approvalForm.get("projectName").touched
    ) {
      this.approvalForm.setControl(
        "applicationName",
        this.fb.array(this.checkedApps.map((name) => this.fb.control(name)))
      );
    }

    // Prepare API data payload
    const apiData = {
      ...this.approvalForm.value,
      projectName: projectNameValue,
      applicationName: [],
      actionSelected: this.actionSelectedApplicationArray,
      applicationUnselected: this.unselectedApplicationArray,
    };

    // Determine applications to send in API
    const currentApplications = this.approvalForm.get("applicationName").value;
    if (apiData.applicationName.length === 0) {
      apiData.applicationName = currentApplications.filter(
        (app) => !this.unselectedApplicationArray.includes(app)
      );
    }

    if (this.approvalForm.value.role === "Super Admin") {
      apiData.applicationName = ["ALL"];
      apiData.actionSelected = null;
      apiData.applicationUnselected = null;
    }

    this.closeForm();

    setTimeout(() => {
      this.spinner.show();
    }, 5000);

    // Make API call
    this.restService.updatePermission(apiData).subscribe(
      (res) => {
        this.syncApplications();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.message);
      }
    );
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  syncApplications() {
    setTimeout(() => {
      this.spinner.show();
    }, 1000);

    this.restApi.syncAllApp().subscribe(
      (data) => {
        this.toastr.success(
          "Approval Successful! Permissions are successfully updated and synced to the downstream application.",
          "Success",
          { timeOut: 3000 }
        );

        setTimeout(() => {
          this.spinner.hide();
          this.closeForm();
        }, 1000);

        if (!this.isDestroyed) {
          this.cdr.detectChanges();
        }
      },
      (error) => {
        if (error != null && error.error != null) {
          this.toastr.error(error.error.error, "HTTP Status: " + error.status);
        } else {
          this.toastr.error(
            "An error occurred",
            "HTTP Status: " + error.status
          );
        }
        console.error(JSON.stringify(error));

        setTimeout(() => {
          this.spinner.hide();
        }, 3000);
      }
    );
  }

  onSubmit(project?: any) {
    if (!this.isEditMode) {
      this.applicationsFetched = true;
    }

    this.applicationsFetched =
      this.approvalForm.value.role === "Super Admin"
        ? true
        : this.applicationsFetched;

    if (!this.validateForm()) {
      return;
    }

    let projectInput = this.approvalForm.get("projectName").value;

    const selectedProject = project || this.selectedProject;

    if (
      !selectedProject.projectName &&
      this.approvalForm.value.role !== "Super Admin" &&
      this.oldProjectName.projectName
    ) {
      selectedProject.projectName = this.oldProjectName.projectName;
      selectedProject.projectCode = this.oldProjectName.projectCode;
    }

    if (this.approvalForm.value.role === "Super Admin") {
      this.approvalForm.patchValue({
        projectName: "ALL",
        applicationName: ["ALL"],
      });
    } else {
      this.approvalForm.patchValue({
        projectName: selectedProject.projectName,
        applicationName: this.approvalForm.value.applicationName,
      });
    }

    if (this.approvalForm.dirty && this.approvalForm.get("role").dirty) {
      this.applicationsFetched = true;
    }

    const applicationArray = this.approvalForm.get(
      "applicationName"
    ) as FormArray;

    if (!applicationArray) {
      console.error("applicationName FormArray is not defined.");
      return false;
    }
  }

  closeForm() {
    this.onClose(true);
  }

  onClose(result: boolean): void {
    this.dialogRef.close(result);
  }
  onReject(project?: any) {
    this.isRejectClicked = true;
    this.applicationsFetched = true;

    // Dynamically set 'remarks' field as required
    const remarksControl = this.approvalForm.get("remarks");
    if (remarksControl) {
      remarksControl.setValidators([Validators.required]);
      remarksControl.updateValueAndValidity();
    }

    let projectInput = this.approvalForm.get("projectName").value;
    const selectedProject = project || this.selectedProject;
    this.selectedProject = project;
    this.approvalForm.get("projectName").value;

    this.selectedProject = {
      projectCode: this.data.userData.project.detail.code,
      projectName: this.data.userData.project.detail.name,
    };

    this.approvalForm.patchValue({
      projectCode: this.data.userData.project.detail.code,

      projectName: this.data.userData.project.detail.name,
    });

    if (!selectedProject.projectName && this.oldProjectName.projectName) {
      selectedProject.projectName = this.oldProjectName.projectName;
      selectedProject.projectCode = this.oldProjectName.projectCode;
    }

    const updatedRole = this.approvalForm.get("role").value;
    this.approvalForm.get("role").setValue(updatedRole);

    let applicationNameValue = this.approvalForm.get("applicationName").value;
    if (!Array.isArray(applicationNameValue)) {
      applicationNameValue = applicationNameValue ? [applicationNameValue] : [];
    }

    if (this.approvalForm.value.role === "Super Admin") {
      this.approvalForm.patchValue({
        projectName: "ALL",
        applicationName: ["ALL"],
      });
    } else {
      this.approvalForm.patchValue({
        projectName: selectedProject.projectName,
        projectCode: selectedProject.projectCode,
        applicationName: this.data.userData.project.detail.applicationName,
      });
    }

    this.approvalForm.value.approved = "false";

    if (this.approvalForm.invalid) {
      return;
    }

    this.applicationsFetched = true;
    this.restService.updatePermission(this.approvalForm.value).subscribe(
      (res) => {
        let msg = res.message;
        if (
          res.message ===
          "Approval Successful! Permissions are successfully updated"
        ) {
          msg = "Rejected successfully!";
        }
        this.applicationsFetched = true;
        this.toastr.success(msg);
        this.onClose(true);
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }
}
