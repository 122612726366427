import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import {
  MatSort,
  MatDialog,
  MatPaginator,
  MatSortable,
} from "@angular/material";
import { UpdateProjectComponent } from "../update-project/update-project.component";
import { RestService } from "../common/rest.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

export interface Project {
  id: string;
  code: string;
  name: string;
  application: [];
  status: string;
  description: string;
  remarks: string;
}

@Component({
  selector: "app-manage-project",
  templateUrl: "./manage-project.component.html",
  styleUrls: ["./manage-project.component.scss"],
})
export class ManageProjectComponent implements OnInit {
  displayedColumns: string[] = [
    "id",
    "name",
    "application",
    "status",
    "action",
  ];
  projectArray = [];
  dataSource = new MatTableDataSource(this.projectArray);

  // isDisabled = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    public restService: RestService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.projectArray);
    this.getAllProjectDetails();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(act, pData): void {
    const dialogRef = this.dialog.open(UpdateProjectComponent, {
      width: "45.5rem",
      data: { projectData: pData, action: act },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllProjectDetails();
      }
    });
  }

  getAllProjectDetails() {
    this.spinner.show();
    this.restService.getAllProjectDetails().subscribe(
      (res) => {
        this.projectArray = res.filter(
          (p) => p.name !== "ALL" && p.status === "ACTIVE"
        );
        this.dataSource = new MatTableDataSource(this.projectArray);

        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error.error.errorMsg, "Error Occured");
      }
    );
  }

  getAllIcmmsProjectDetails() {
    this.spinner.show();
    this.restService.getAllIcmmsProjectDetails().subscribe(
      (res) => {
        // this.isDisabled = true;
        this.toastr.success(res.response);
        if (res.p) {
          this.getAllProjectDetails();
          this.projectArray = res.p.filter((p) => p.name !== "ALL");
          this.dataSource = new MatTableDataSource(this.projectArray);

          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.spinner.hide();

          // this.isDisabled = false;
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.warning(error.error.message);
      }
    );
  }
}
